import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Anchor, Heading, Paragraph } from '@lux/components-gomo';
import styled from 'styled-components';
import withLayout from '../components/withLayout';
import { remCalc } from '@lux/helpers';

import {
  PRODUCT_NAME,
  MAINTENANCE,
  RETURN_TO_HOME_PAGE
} from '../constants/page_content.json';
import { HOME_PAGE, CONTACT_PAGE } from '../constants/links.json';

const Container = styled.div`
  text-align: center;
`;

const Section = styled.div`
  margin: ${remCalc(20)} 0;
`;

const Failure = () => {
  return (
    <Fragment>
      <Helmet defaultTitle={PRODUCT_NAME} titleTemplate="%s">
        <title>{MAINTENANCE.TITLE}</title>
      </Helmet>
      <Container>
        <Heading level={2}>{MAINTENANCE.HEADING.toUpperCase()}</Heading>
        <Section>
          <Paragraph>
            {MAINTENANCE.DESCRIPTION[0]}
            <Anchor href={CONTACT_PAGE.ABSOLUTE}>
              {MAINTENANCE.DESCRIPTION[1]}
            </Anchor>
            {MAINTENANCE.DESCRIPTION[2]}
          </Paragraph>
        </Section>
        <Section>
          <Anchor href={HOME_PAGE.ABSOLUTE}>{RETURN_TO_HOME_PAGE}</Anchor>
        </Section>
      </Container>
    </Fragment>
  );
};

export default withLayout(Failure);
